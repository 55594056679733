module.exports = Promise.resolve({name: 'estimates', url: 'https://estimates.bluebeam-dev.com/remoteEntry.js'}).then((remote) => new Promise((resolve, reject) => {
    // Define a script tag to use the browser for fetching the remoteEntry.js file.
    const script = document.createElement('script');
    script.async = true;
    script.src = remote.url;
    script.onerror = reject;
    // Resolve the promise back to Module Federation with the required get/init interface.
    // The script is loaded on window using the name defined within the remote.
    script.onload = () => resolve({
        get: (req) => window[remote.name].get(req),
        init: (arg) => {
            try {
                window[remote.name].init(arg);
            }
            catch (e) {
                // Log and ignore errors.
                console.error(e);
            }
        },
    });
    // Inject the script tag into the document's head to trigger the script load.
    document.head.appendChild(script);
}).catch((e) => {
    // Log and ignore errors.
    console.error(e);
}));